import "index.css"
import "syntax-highlighting.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.start()

console.info("Bridgetown is loaded!")

const scrLock = Array.from(document.querySelectorAll('.scroll-lock'))
const scrUnlock = Array.from(document.querySelectorAll('.scroll-unlock'))

scrLock.forEach(el => {
  el.addEventListener('click', () => {
    document.querySelector('body').classList.add('body-scroll-lock')
  });
})

scrUnlock.forEach(el => {
  el.addEventListener('click', () => {
    document.querySelector('body').classList.remove('body-scroll-lock')
  });
})